// Fontawesome
// icon font pack

// import "@fortawesome/fontawesome-free/js/all";

import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faMapPin,
  faPrint,
  faEnvelope,
  faAngleLeft,
  faAngleRight,
  faMinus,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faPinterest,
  faInstagram,
  faHouzz
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(() => {
  library.add(
    faFacebook,
    faPinterest,
    faInstagram,
    faHouzz,
    faSearch,
    faTimes,
    faMapPin,
    faPrint,
    faEnvelope,
    faAngleLeft,
    faAngleRight,
    faMinus,
    faPlus
  );

  dom.i2svg();
});
