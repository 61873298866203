$(document).ready(() => {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").removeClass("navbar--scrolled");
    $("#search").removeClass("search--opened");
    $("#searchOpen").show();
    $("#searchClose").hide();
    $("#searchBtn").fadeOut(350);
  };

  const menuClose = () => {
    $("#menu").removeClass("menu--opened");
    setTimeout(function() {
      $("body").css("overflow", "auto");
      $("#searchBtn").fadeIn(350);
      if ($(window).scrollTop() > 50) {
        $(".navbar").addClass("navbar--scrolled");
      }
    }, 660);
  };

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });

  // search

  const searchOpen = () => {
    $("body").css("overflow", "hidden");
    $("#search").addClass("search--opened");
    $("#searchOpen").hide();
    $("#searchClose").show();
    $(".search__form input").focus();
  };

  const searchClose = () => {
    $("body").css("overflow", "auto");
    $("#search").removeClass("search--opened");
    $("#searchOpen").show();
    $("#searchClose").hide();
  };

  $("#searchBtn").on("click", function() {
    $(this).toggleClass("search__btn--clicked");
    if ($(this).hasClass("search__btn--clicked")) {
      searchOpen();
    } else {
      searchClose();
    }
  });
});
